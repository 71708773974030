<template>
  <div>
    <v-container>
      <h1 class="pb-6">Weather Reports</h1>
      <v-row>
        <v-col v-for="(menu, idx) in menus" :key="idx" cols="6" md="3" sm="3">
          <v-card ripple link :to="'/' + menu.url" rounded="lg" class="pa-6">
            <v-img class="ma-6" :src="getImage(menu.img)"></v-img>
            <p class="text-center">{{ menu.title }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menus: [
        {
          img: "government_early_warning.svg",
          title: "Government Early Warning",
        },
        {
          img: "environmental_trend_analysis.svg",
          title: "Environmental Trend Analysis",
        },
        {
          img: "planting_and_fertilizer_dates.svg",
          title: "Planting and Fertilizer Dates",
        },
        {
          img: "irrigation_recommendations.svg",
          title: "Irrigation Recommendations",
        },
        {
          img: "pest_and_disease_trends.svg",
          title: "Pest and Disease Trends",
        },
        {
          img: "similar_location_comparisons.svg",
          title: "Similar Location Comparisons",
        },
        {
          img: "weather_variability_impacting_food_supply.svg",
          title: "Weather Variability Impacting Food Supply",
        },
        {
          img: "historical_data_15_day_forecast.svg",
          title: "Historical Data 15-Day Forecast",
        },
        {
          img: "precipitation_temperature_humidity_wind_solar.svg",
          title: "Precipitation, Temperature, Humidity, Wind, Solar",
        },
        {
          img: "growing_degree_days.svg",
          title: "Growing Degree Days",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return "/img/my_reports/weather_reports/" + img;
    },
  },
};
</script>
